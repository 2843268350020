import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';

import Layout from './components/Layout';
import AtriasCookieBanner from './components/Base/AtriasCookieBanner';
import TelemetryProvider from './telemetry/TelemetryProvider';

import Home from './containers/Home';
import About from './containers/About';
import CurrentDocumentation from './containers/CurrentDocumentation';
import Search from './containers/Search';
import ScrollToTop from './components/ScrollToTop';
import SectorData from './containers/SectorData';
import Projects from './containers/Projects';
import NewMembers from './containers/NewMembers';
import MissionVision from './containers/MissionVision';
import Jobs from './containers/Jobs';
import MyAtriasContainer from './containers/MyAtrias';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './services/msal-config';
import { MsalUtilsConfigurator } from './utils/MsalUtilsConfigurator';
import FileDownload from './containers/FileDownload/FileDownload';
import Faq from './containers/Faq';

const Terms = React.lazy(() => import('./containers/Terms'));
const NotFound = React.lazy(() => import('./containers/NotFound'));
const ServerError = React.lazy(() => import('./containers/ServerError'));
const CompanyInfo = React.lazy(() => import('./containers/CompanyInfo'));
const AnnualReports = React.lazy(() => import('./containers/AnnualReports'));
const Privacy = React.lazy(() => import('./containers/Privacy'));
const Cookies = React.lazy(() => import('./containers/Cookies'));
const EmailDisclaimer = React.lazy(() => import('./containers/EmailDisclaimer'));

function App({ t }) {

  const msalInstance = new PublicClientApplication(msalConfig);
  const dutchFaqPath = 'New-Members/04 UMIG 6.5 Project Documents/FAQ Go-live CMS Atrias NL.pdf';
  const frenchFaqPath = 'New-Members/04 UMIG 6.5 Project Documents/FAQ Go-live CMS Atrias FR.pdf';
  return (
    <>
      <Helmet>
        <title>Atrias</title>
        <meta name="description" content={t('meta.default-description')} />
        {window.runConfig.environment === 'PROD' ? null :  <meta name="robots" content="noindex"></meta>
        
        }
      </Helmet>
      <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <BrowserRouter>
            <TelemetryProvider instrumentationKey={window.runConfig.insightsCode}>
              <ScrollToTop />
              <AtriasCookieBanner />
              <Layout>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/about/mission-vision" component={MissionVision} />
                  <Route exact path="/current-documentation" component={CurrentDocumentation} />
                  <Route exact path="/search" component={Search} />
                  <Route exact path="/sector-data" component={SectorData} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/company-info" component={CompanyInfo} />
                  <Route exact path="/500" component={ServerError} />
                  <Route exact path="/annual-reports" component={AnnualReports} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/cookie-policy" component={Cookies} />
                  <Route exact path="/email-disclaimer" component={EmailDisclaimer} />
                  <Route exact path="/new-members" component={NewMembers} />
                  <Route exact path="/projects" component={Projects} />
                  <Route exact path="/jobs" component={Jobs} />
                  <Route exact path="/faq" component={Faq} />
                  <Route exact path="/applications" component={MyAtriasContainer} />
                  <Route exact path="/FAQ_NL" render={() => (
                    <FileDownload fileUrl={dutchFaqPath} />
                  )}  />
                  <Route exact path="/FAQ_FR" render={() => (
                    <FileDownload fileUrl={frenchFaqPath} />
                  )}  />
                  {
                    /*
                    Some routes to redirect to the main page from links present on the old website
                    */ 
                  }
                  <Route path="/NL">
                    <Redirect to="/" /> 
                  </Route>
                  <Route path="/FR">
                    <Redirect to="/" /> 
                  </Route>
                  <Route path="/UK">
                    <Redirect to="/" /> 
                  </Route>
                  <Route path="*.aspx">
                    <Redirect to="/" /> 
                  </Route>
                  <Route path="*" component={NotFound} />
                </Switch>
              </Layout>
            </TelemetryProvider>
          </BrowserRouter>
        </StylesProvider>
      </ThemeProvider>
      <MsalUtilsConfigurator />
      </MsalProvider>
    </>
  );
}

App.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(App);
